<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="checkForm"
  >
    <v-row>
      <h5 class="text-center"><strong>{{ $t('message.forgot_password_title') }}</strong></h5>

      <br>
      <br>

      <p class="text-sm-body-1">
        {{ $t('message.fill_form_to_reset_password') }}
      </p>
      <v-alert
        outlined
        type="info"
        border="left"
        class="text-center"
      >
        <strong>
          {{ $t('message.reset_link_info') }}
        </strong>
        <br><br>
        <strong>
          {{ $t('message.spam_reminder') }}
        </strong>
      </v-alert>

      <v-col cols="12" sm="12" md="12">
        <p v-if="successMessage">
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>
        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <v-text-field
          ref="email"
          autocomplete="username"
          v-model="email"
          :rules="emailRules"
          :label="$t('message.label_email')"
          @input="checkEmail()"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <button type="submit" :disabled="!valid" class="btn btn-centered" :class="valid ? 'btn-primary' : ''">{{ $t('message.reset_password') }}</button>

      <router-link to="/connexion" class="recovery mt-2 text-left">{{ $t('message.back_to_login') }}</router-link>
    </v-row>

    <div class="activation">
      {{ $t('message.not_registered_yet') }}

      <router-link to="/activation/etape-1">{{ $t('message.activation') }} <i class="mdi mdi-exit-to-app"></i></router-link>
    </div>
  </v-form>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import Vue from 'vue'
import config from '../../config'
import router from '../../router'

export default {
  name: 'ForgotPassword',
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    email: ''
  }),
  created () {
    this.$emit('update:layout', LoginLayout)
    document.title = config.title + ' - ' + this.$t('message.forgot_password')
  },
  computed: {
    emailRules () {
      return [
        v => !!v || this.$t('message.mandatory_email'),
        v => /.+@.+\..+/.test(v) || this.$t('message.mandatory_valid_email')
      ]
    }
  },
  methods: {
    checkEmail () {
      this.valid = !!this.$refs.email.valid
    },
    checkForm () {
      if (this.valid) {
        this.forgotPassword()
      }
    },
    forgotPassword () {
      const data = {
        email: this.email.toLowerCase()
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/forgot-password', formData)
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              router.push({ name: 'Login', query: { forgot: 'true' } })
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }
        })
    }
  }
}
</script>

<style>
.btn-centered {
  width: auto;
  margin: 0 auto;
}
</style>
